import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const Faqs = ({ data }) => {
  return (
    <>
      <section id={convertId(data.name)} className="section faqs">
        <div className="container">
          <Content className="faqs-title mb-5 text-center">
            {data.faqTitle}
          </Content>
          <div className="grid">
            {data.faqItems.map((faqItem, index) => (
              <div key={index} className="grid-item">
                <h3>{faqItem.question}</h3>
                <Content>{faqItem.answer}</Content>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment Faqs on WpPage_Sections_Sections_Faqs {
    fieldGroupName
    name
    faqTitle
    faqItems {
      question
      answer
    }
  }
`;

export default Faqs;
